﻿/*
  homepage.js
  Scripts for both simon.com and premiumoutlets.com homepages
*/

// add slider script
require('../../ga/scripts/partials/slickslider');
const spoCarousel = require('./spo-carousel');

(function () {
  // ie check
  const detectIE = function () {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');
    const edge = ua.indexOf('Edge/');

    if(msie > 0 || trident > 0 || edge > 0) {
      return true;
    }

    // other browser
    return false;
  };

  document.addEventListener('DOMContentLoaded', function () {
    $('.explore-slider').slick({
      accessibility: false,
      dots: true,
      infinite: false,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick'
        }
      ]
    });

    $('.spg-ad-spot a, a.spg-ad-spot').mouseenter(function () {
      $(this).closest('.spg-ad-spot').find('.spg-ad-spot-image-container').addClass('cta-hover');
    }).mouseleave(function () {
      $(this).closest('.spg-ad-spot').find('.spg-ad-spot-image-container').removeClass('cta-hover');
    });
  }, false);

  $(window).on('orientationchange resize', function () {
    $('.explore-slider').slick('resize');
  });

  // video controls
  const htmlVideoControls = function htmlVideoControls() {
    const arrow = document.querySelector('.js-down-arrow');
    const volumeToggle = document.querySelector('.js-volume');
    const playToggle = document.querySelectorAll('.js-play');
    const pauseToggle = document.querySelector('.js-pause');
    const video = document.getElementById('video');
    // const vidContainer = document.querySelector('.video-container');
    const vidContainer = document.querySelector('.spg-video-hero') !== null ? document.querySelector('.spg-video-hero') : document.querySelector('.video-container');
    // const textOverVideo = document.querySelector('.video-overlay');
    const textOverVideo = document.querySelector('.overlay') !== null ? document.querySelector('.overlay') : document.querySelector('.video-overlay');
    const videoFallback = document.querySelector('.js-video-img');
    const scrollToLocation = window.innerHeight - 74;
    const isIE = detectIE();
    let lastKnownScrollPosition = 0;
    let ticking = false;
    const windowHeight = window.innerHeight;
    const isVideo = document.getElementsByTagName('video').length;
    const toggleVideoOpacity = (scrollPos) => {
      // do something with the scroll position
      if(vidContainer) {
        if(scrollPos >= windowHeight && (vidContainer.style.opacity === '' || vidContainer.style.opacity === '1')) {
          vidContainer.style.opacity = 0;
        } else if(scrollPos < windowHeight && vidContainer.style.opacity === '0') {
          vidContainer.style.opacity = 1;
        }
      }
    };
    const onWindowScroll = () => {
      lastKnownScrollPosition = isIE ? window.pageYOffset : window.scrollY;

      if(!ticking) {
        window.requestAnimationFrame(() => {
          toggleVideoOpacity(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    };

    // pauses video
    const pauseVideo = function () {
      video.pause();
      playToggle[0].classList.add('paused');
      if(playToggle[1]) playToggle[1].classList.add('paused');
      pauseToggle.classList.remove('on');
      pauseToggle.classList.add('off');
    };

    // plays video
    const playVideo = function () {
      video.play();
      playToggle[0].classList.remove('paused');
      if(playToggle[1]) playToggle[1].classList.remove('paused');
      pauseToggle.classList.remove('off');
      pauseToggle.classList.add('on');
    };

    const scrollTo = function (element, to, duration) {
      if(duration <= 0) {
        return;
      }

      const difference = to - element.scrollTop;
      const perTick = difference / duration * 10;

      setTimeout(function () {
        element.scrollTop = element.scrollTop + perTick;
        if(element.scrollTop === to) {
          return;
        }
        scrollTo(element, to, duration - 10);
      }, 10);
    };

    if(isIE) {
      // IE / Edge video workaround for object-fit: cover;
      $('.main-hero').addClass('ms-video-workaround');
    }

    if(window.innerWidth <= 992) {
      if(isVideo && video) {
        video.addEventListener('loadeddata', () => {
          video.removeAttribute('autoplay');
          pauseVideo();
        });
      }
      if(textOverVideo !== null) {
        textOverVideo.classList.add('show');
      }
      if(vidContainer) vidContainer.style.opacity = 1;
      window.removeEventListener('scroll', onWindowScroll, false);
    } else {
      if(isIE) {
        video.style.height = 'auto';
      }
      if(isVideo) {
        // if the video is loaded - show the overlay text with a fade in effect
        // video.oncanplaythrough = document.querySelector('.video-overlay').classList.add('show');
        video.oncanplaythrough = document.querySelector('.video-overlay') !== null ? document.querySelector('.video-overlay').classList.add('show') : document.querySelector('.overlay').classList.add('show');
      }
      // play the video
      if(playToggle[0]) {
        playVideo();
      }
      window.addEventListener('scroll', onWindowScroll);
    }

    if(document.querySelector('footer')) {
      document.querySelector('footer').classList.add('footer-homepage');
    }

    if(textOverVideo !== null) {
      // clicking on anything else besides the toggles takes to ext url via data-attr on video
      textOverVideo.addEventListener('click', function () {
        if(typeof video.attributes['data-href'] !== 'undefined') window.location.href = video.attributes['data-href'].value;
      });
    }
    // event listener for the play button
    if(playToggle[0]) {
      playToggle[0].addEventListener('click', function (e) {
        e.stopPropagation();
        if(this.classList.contains('paused')) {
          playVideo();
          if(volumeToggle) volumeToggle.click();
        } else {
          pauseVideo();
          if(volumeToggle) volumeToggle.click();
        }
      });
    }
    if(playToggle[1]) {
      playToggle[1].addEventListener('click', function (e) {
        e.stopPropagation();
        if(this.classList.contains('paused')) {
          playVideo();
          if(volumeToggle) volumeToggle.click();
        } else {
          pauseVideo();
          if(volumeToggle) volumeToggle.click();
        }
      });
    }
    // event listener for the pause button
    if(pauseToggle) {
      pauseToggle.addEventListener('click', function (e) {
        e.stopPropagation();
        if(this.classList.contains('on')) {
          pauseVideo();
          if(volumeToggle) volumeToggle.click();
        }
      });
    }

    // Event listener for the mute button
    if(volumeToggle) {
      volumeToggle.addEventListener('click', function (e) {
        e.stopPropagation();
        if(video.muted) {
          // Unmute the video
          video.muted = false;
          // update the toggle
          volumeToggle.classList.remove('off');
          volumeToggle.classList.add('on');
        } else {
          // Mute the video
          video.muted = true;
          // update the toggle
          volumeToggle.classList.add('off');
          volumeToggle.classList.remove('on');
        }
      });
    }

    // on click of arrow scroll down to other page content
    if(arrow) {
      arrow.addEventListener('click', function (e) {
        e.stopPropagation();
        const el = document.scrollingElement ? document.scrollingElement : document.documentElement;
        $(el).animate({ scrollTop: scrollToLocation }, 400);
      });
    }
  };

  // init slick slider for PO only
  // TODO - jquery slick - find alternative?
  if(document.location.href.indexOf('premiumoutlets') >= 0) {
    if(document.querySelector('.spo-carousel')) {
      spoCarousel.init();
    }

    $('.blob-slider').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      autoplay: true,
      autoplaySpeed: 6000,
      accessibility: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    $('.slick-arrow, .slick-dots button').on('click', function () {
      $('.blob-slider').slick('slickPause');
    });

    $('.blob-slider').on('swipe', function () {
      $('.blob-slider').slick('slickPause');
    });
  }

  // init shop online slider
  $('.shop-online-slider').slick({
    accessibility: true,
    dots: false,
    infinite: false,
    swipeToSlide: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: false
        }
      }
    ]
  });

  $('.slick-arrow, .slick-dots button').on('click', function () {
    $('.blob-slider').slick('slickPause');
  });

  $('.blob-slider').on('swipe', function () {
    $('.blob-slider').slick('slickPause');
  });

  htmlVideoControls();
})();
